<template>
    <div>
        <!-- Contact Section -->
        <section class="contact-section style-two">
            <div class="auto-container">
                <div class="row">
                    <!-- Form Column -->
                    <div class="form-column col-lg-8 col-md-6 col-sm-12">
                        <div class="inner-column">
                            <div class="title-box">
                                <h2>تواصل معنا</h2>
                                <div class="text">
                                    يسعدنا تواصلك معنا فلا تتردد فى مراسلتنا
                                </div>
                            </div>

                            <!-- Contact Form -->
                            <div class="contact-form">
                                <vee-form
                                    :validation-schema="ContactFormValidation"
                                    @submit="sendEmail"
                                >
                                    <div class="form-group">
                                        <Field
                                            name="name"
                                            v-model="message.name"
                                            type="text"
                                            placeholder="الإسم"
                                        />
                                        <ErrorMessage
                                            class="has-error"
                                            name="name"
                                        />
                                    </div>

                                    <div class="form-group">
                                        <Field
                                        name="email"
                                            v-model="message.email"
                                            type="email"
                                            placeholder="الإيميل"
                                        />
                                        <ErrorMessage
                                            class="has-error"
                                            name="email"
                                        />
                                    </div>

                                    <div class="form-group">
                                        <Field
                                        name="subject"
                                            v-model="message.subject"
                                            type="text"
                                            placeholder="العنوان"
                                            required
                                        />
                                        <ErrorMessage
                                            class="has-error"
                                            name="subject"
                                        />
                                    </div>

                                    <div class="form-group">
                                        <Field
                                        as="textarea"
                                        name="message"
                                            v-model="message.message"
                                            placeholder="الرسالة"
                                        />
                                        <ErrorMessage
                                            class="has-error"
                                            name="message"
                                        />
                                    </div>

                                    <div class="form-group">
                                        <button
                                            class="theme-btn btn-style-one"
                                            type="submit"
                                            name="submit-form"
                                        >
                                            إرسال
                                        </button>
                                    </div>
                                </vee-form>
                            </div>
                        </div>
                    </div>

                    <!-- Info Column -->
                    <div class="info-column col-lg-4 col-md-6 col-sm-12">
                        <div class="inner-column">
                            <!-- Info Box -->
                            <div class="contact-info-box">
                                <div class="inner-box">
                                    <span class="icon la la-phone"></span>
                                    <h4>الجوال</h4>
                                    <ul>
                                        <li
                                            v-for="(
                                                phone, index
                                            ) in settings.phones"
                                            :key="index"
                                        >
                                            {{ phone }}
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <!-- Info Box -->
                            <div class="contact-info-box">
                                <div class="inner-box">
                                    <span class="icon la la-envelope-o"></span>
                                    <h4>الإيميلات</h4>
                                    <ul>
                                        <li
                                            v-for="(
                                                email, index
                                            ) in settings.emails"
                                            :key="index"
                                        >
                                            {{ email }}
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <!-- Info Box -->
                            <div class="contact-info-box">
                                <div class="inner-box">
                                    <span class="icon la la-globe"></span>
                                    <h4>العناوين</h4>
                                    <ul>
                                        <li
                                            v-for="(
                                                address, index
                                            ) in settings.addresses"
                                            :key="index"
                                        >
                                            {{ address }}
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <!-- Info Box -->
                            <div class="contact-info-box follow-us">
                                <div class="inner-box">
                                    <h4>وسائل التواصل:</h4>
                                    <ul class="social-icon-three">
                                        <li v-if="settings.social.facebook">
                                            <a
                                                :href="settings.social.facebook"
                                                target="_blank"
                                                ><span
                                                    class="la la-facebook-f"
                                                ></span
                                            ></a>
                                        </li>
                                        <li v-if="settings.social.twitter">
                                            <a
                                                :href="settings.social.twitter"
                                                target="_blank"
                                                ><span
                                                    class="la la-twitter"
                                                ></span
                                            ></a>
                                        </li>
                                        <li v-if="settings.social.google_plus">
                                            <a
                                                :href="
                                                    settings.social.google_plus
                                                "
                                                target="_blank"
                                                ><span
                                                    class="la la-google-plus"
                                                ></span
                                            ></a>
                                        </li>
                                        <li v-if="settings.social.instagram">
                                            <a
                                                :href="
                                                    settings.social.instagram
                                                "
                                                target="_blank"
                                                ><span
                                                    class="la la-instagram"
                                                ></span
                                            ></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!--End Contact Section -->
    </div>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import ContactFormValidation from "../forms/contactFormValidation";

export default {
    name: "ContactUs",
    components: {
        "vee-form": Form,
        Field,
        ErrorMessage,
    },
    data() {
        return {
            settings: {
                phones: [],
                emails: [],
                addresses: [],
                social: {},
            },
            message: {},
            ContactFormValidation,
        };
    },
    mounted() {
        this.$axios.get("settings").then((res) => {
            this.settings = res.data.settings;
        });
    },
    methods: {
        sendEmail() {
            this.$axios.post("/users/contact-us", this.message);
        },
    },
};
</script>
